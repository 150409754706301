import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDfxjRkF3YeUeFqdEStc8TWunxDsfQfREY",
    authDomain: "zq-signal.firebaseapp.com",
    databaseURL: "https://zq-signal-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "zq-signal",
    storageBucket: "zq-signal.appspot.com",
    messagingSenderId: "968175391599",
    appId: "1:968175391599:web:0e8ad3d1c5b7b73ef6ce9a",
    measurementId: "G-FR3XFZR1CB"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
export default app;
