// src/utils/notification.js
export class NotificationManager {
  constructor() {
    this.audioContext = null;
    this.hasInteracted = false;
    this.soundEnabled = localStorage.getItem('soundEnabled') === 'true';
  }

  // Kullanıcı etkileşimi ve izinleri kontrol et
  async initialize() {
    // Bildirim izni iste
    if ("Notification" in window && Notification.permission === "default") {
      await Notification.requestPermission();
    }

    // Ses için AudioContext oluştur
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      await this.audioContext.resume();
    }

    this.hasInteracted = true;
    console.log('NotificationManager initialized', {
      notificationPermission: Notification.permission,
      audioContext: this.audioContext.state,
      soundEnabled: this.soundEnabled
    });
  }

  async playSound() {
    if (!this.soundEnabled) {
      console.log('Sound is disabled');
      return;
    }

    try {
      // Ses için yeni bir AudioContext oluştur
      if (!this.audioContext || this.audioContext.state !== 'running') {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        await this.audioContext.resume();
      }

      const audio = new Audio('/moneyAlert.mp3');
      await audio.play();
      console.log('Sound played successfully');
    } catch (error) {
      console.error('Sound play error:', error);
    }
  }

  async showNotification(title, body) {
    if (!("Notification" in window)) {
      console.log('Notifications not supported');
      return;
    }

    if (Notification.permission === "granted") {
      new Notification(title, {
        body,
        icon: '/images/logo.png',
        requireInteraction: true
      });
      console.log('Notification shown');
    } else {
      console.log('Notification permission:', Notification.permission);
    }
  }

  async notifyPositionClose(position) {
    console.log('Starting position close notification:', {
      position,
      soundEnabled: this.soundEnabled,
      hasInteracted: this.hasInteracted,
      notificationPermission: Notification.permission
    });
  
    try {
      await this.playSound();
      console.log('Sound played successfully');
    } catch (error) {
      console.error('Sound play failed:', error);
    }
  
    try {
      await this.showNotification(
        'Pozisyon Kapandı',
        `${position.symbol}: $${parseFloat(position.profitOrLoss).toFixed(2)}`
      );
      console.log('Notification shown successfully');
    } catch (error) {
      console.error('Notification show failed:', error);
    }
  }

  toggleSound() {
    this.soundEnabled = !this.soundEnabled;
    localStorage.setItem('soundEnabled', this.soundEnabled);
    console.log('Sound toggled:', this.soundEnabled);
    return this.soundEnabled;
  }
}

export const notificationManager = new NotificationManager();