import React from 'react';
import TradingDashboard from './components/TradingDashboard';

function App() {
  return (
    <div>
      <TradingDashboard />
    </div>
  );
}

export default App;