import React, { useState, useEffect, useCallback } from 'react';
import { onValue, ref, update,remove } from 'firebase/database';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { db, auth } from '../firebase/config';
import { notificationManager } from '../utils/notification';
import Stats from './Stats';  // Stats komponentini import et
import WalletStatsModal from './WalletStatsModal'; 
import RiskStatus from './RiskStatus';


const TradingDashboard = () => {
  const [user, setUser] = useState(null);
  const [positions, setPositions] = useState([]);
  const [activeTab, setActiveTab] = useState('closed');
  const [selectedPosition, setSelectedPosition] = useState(null);
 
  const [selectedPositionKey, setSelectedPositionKey] = useState(null);

  const [coinLogos, setCoinLogos] = useState({});
  const [showAllPositions, setShowAllPositions] = useState(false);
  const [previousPositions, setPreviousPositions] = useState([]);
  
  
  
    // Modal ve stats için state'ler
    const [showWalletStats, setShowWalletStats] = useState(false);
    const [pnlUpdateModalOpen, setPnlUpdateModalOpen] = useState(false);
    const [newPnlValue, setNewPnlValue] = useState('');
    const [hourlyStats, setHourlyStats] = useState({
      totalHours: 0,
      totalProfit: 0,
      totalTrades: 0,
      avgProfitPerTrade: 0,
      avgTradesPerHour: 0
    });
    const [stats, setStats] = useState({
      totalProfitLoss: "0.00",
      totalTrades: 0,
      dailyProfit: "0.00",
      dailyTrades: 0,
      hourlyProfit: "0.00",
      hourlyTrades: "0.00"
    });
  
    // Wallet bilgileri state'i
    const [walletInfo, setWalletInfo] = useState({
      totalWalletBalance: "0",
      totalAvailableBalance: "0",
      totalMarginBalance: "0",
      totalMaintenanceMargin: "0",
      accountIMRate: "0",
      accountMMRate: "0",
      coin: []
    });
  
  
  const [isSoundEnabled, setIsSoundEnabled] = useState(() => {
    const savedPreference = localStorage.getItem('soundEnabled');
    return savedPreference ? JSON.parse(savedPreference) : true;
  });

  
  
 

 
 // Wallet bilgilerini al
 useEffect(() => {
  if (!user) return;

  const walletRef = ref(db, '/wallet_info');
  
  const unsubscribe = onValue(walletRef, (snapshot) => {
    const data = snapshot.val();
    //console.log("Raw Wallet data:", data); // Tüm veriyi görelim
    
    if (data) {
      // Coin verisini dönüştür
      const coinData = data.coin ? Object.entries(data.coin).map(([key, value]) => ({
        ...value,
        id: key
      })) : [];

      const walletData = {
        totalWalletBalance: data.totalWalletBalance || "0",
        totalAvailableBalance: data.totalAvailableBalance || "0",
        totalMarginBalance: data.totalMarginBalance || "0",
        totalMaintenanceMargin: data.totalMaintenanceMargin || "0",
        accountIMRate: data.accountIMRate || "0",
        accountMMRate: data.accountMMRate || "0",
        totalEquity: data.totalEquity || "0",
        totalInitialMargin: data.totalInitialMargin || "0",
        coin: coinData
      };
      
      //console.log("Processed wallet data:", walletData);
      setWalletInfo(walletData);
    }
  }, (error) => {
    console.error("Wallet bilgileri alınamadı:", error);
  });

  return () => unsubscribe();
}, [user]);

 


  // Pozisyon seçildiğinde key'i de kaydet
const handlePositionClick = (position, key) => {
  setSelectedPosition(position);
  setSelectedPositionKey(key);  setPnlUpdateModalOpen(false);
};

 

  const INITIAL_POSITION_COUNT = 7;
 
 

  // Toggle sound fonksiyonu
  const toggleSound = useCallback(async () => {
    const isEnabled = notificationManager.toggleSound();
    setIsSoundEnabled(isEnabled);
    
    if (isEnabled) {
      await notificationManager.playSound();
      notificationManager.showNotification('Ses Sistemi', {
        body: 'Ses sistemi aktif edildi!'
      });
    }
  }, []);
 


// Component içinde yeni bir useEffect ekleyelim
useEffect(() => {
  // Sayfa yüklendiğinde notification manager'ı başlat
  const initializeNotifications = async () => {
    try {
      await notificationManager.initialize();
      console.log('Notification system initialized');
    } catch (error) {
      console.error('Notification initialization error:', error);
    }
  };

  initializeNotifications();
}, []); // Sadece bir kere çalışsın


// Silme işlemi için fonksiyon
const handleDeletePosition = (positionKey) => {
  // Onay al (örneğin, bir modal veya confirm ile)
  if (window.confirm("Bu pozisyonu silmek istediğinize emin misiniz?")) {
    setSelectedPosition(null)
    const positionRef = ref(db, `positions/${positionKey}`);
    remove(positionRef)
      .then(() => {
        // Silme işlemi başarılı
        console.log("Pozisyon silindi");
        
        // Pozisyon listesini güncelle (setPositions)
      })
      .catch((error) => {
        console.error("Silme işlemi sırasında hata oluştu:", error);
      });
  }
};




     // PNL güncelleme fonksiyonu
const handlePnlUpdate = useCallback(async () => {
  try {
    if (!selectedPositionKey) {
      console.error('Position key bulunamadı');
      return;
    }

    console.log('Güncelleme başlatılıyor:', {
      key: selectedPositionKey,
      newValue: newPnlValue
    });

    // Firebase referansını doğrudan key ile oluştur
    const positionRef = ref(db, `positions/${selectedPositionKey}`);
    
    // Değerleri güncelle
    await update(positionRef, {
      profitOrLoss: newPnlValue,
      profitOrLossPercentage: selectedPosition.entryPrice ? 
        ((parseFloat(newPnlValue) / parseFloat(selectedPosition.entryPrice)) * 100).toFixed(2) : 
        "0"
    });

    console.log('PNL başarıyla güncellendi');
    setPnlUpdateModalOpen(false);
    setNewPnlValue('');

  } catch (error) {
    console.error('PNL güncelleme hatası:', error);
  }
}, [selectedPositionKey, newPnlValue, selectedPosition]);

  // Coin logolarını yükle
  useEffect(() => {
    const loadLogos = async () => {
      try {
        const response = await fetch('/logos.json');
        const logos = await response.json();
        setCoinLogos(logos);
      } catch (error) {
        console.error('Logolar yüklenemedi:', error);
      }
    };
    loadLogos();
  }, []);

  // Authentication
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser && (currentUser.email === "zaferkekik@gmail.com" || currentUser.email === "gulten@zake.com.tr")) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);


  
// Firebase real-time data subscription
useEffect(() => {
  if (!user) return;

  const positionsRef = ref(db, 'positions');
  let lastNotificationTime = Date.now();
  const NOTIFICATION_DELAY = 1000;

  // Bildirim gönderilen pozisyonları takip etmek için bir Set kullanalım
  const notifiedPositions = new Set();

  const unsubscribe = onValue(positionsRef, (snapshot) => {
    const data = snapshot.val();
    if (!data) return;

 

    // Position array'ini key'lerle birlikte oluştur
    const positionsArray = Object.entries(data).map(([key, position]) => ({
      ...position,
      key // Her position objesine key'ini ekle
    })).sort((a, b) => {
      // Önce kapanış tarihine göre sırala
      const aCloseTime = a.closedTimestamp ? new Date(a.closedTimestamp) : new Date(0);
      const bCloseTime = b.closedTimestamp ? new Date(b.closedTimestamp) : new Date(0);
      
      // Kapanış tarihi varsa ona göre, yoksa giriş tarihine göre sırala
      if (a.status === 'closed' && b.status === 'closed') {
        return bCloseTime - aCloseTime; // Yeniden eskiye
      } else if (a.status === 'closed') {
        return -1; // Kapalı işlemler üstte
      } else if (b.status === 'closed') {
        return 1; // Kapalı işlemler üstte
      } else {
        // Her ikisi de açık işlemse giriş tarihine göre sırala
        return new Date(b.timestamp) - new Date(a.timestamp);
      }
    });

     // Yeni kapanan pozisyonları kontrol et
     if (previousPositions.length > 0) {
      const currentTime = Date.now();
      const newClosedPositions = positionsArray.filter(position => {
        // Eğer bu pozisyon için daha önce bildirim göndermişsek, filtreleme
        if (notifiedPositions.has(position.timestamp)) {
          return false;
        }

        const isNewlyClosed = position.status === 'closed' && 
          !previousPositions.find(prev => 
            prev.timestamp === position.timestamp && 
            prev.status === 'closed'
          );

        // Eğer yeni kapanan bir pozisyonsa, Set'e ekle
        if (isNewlyClosed) {
          notifiedPositions.add(position.timestamp);
          console.log('New closed position detected:', {
            symbol: position.symbol,
            timestamp: position.timestamp
          });
        }

        return isNewlyClosed;
      });

      if (newClosedPositions.length > 0 && 
          (currentTime - lastNotificationTime) > NOTIFICATION_DELAY) {
        console.log('Sending notification for:', newClosedPositions[0].symbol);
        
        if (isSoundEnabled) {
          notificationManager.notifyPositionClose(newClosedPositions[0])
            .then(() => {
              console.log('Notification sent for:', newClosedPositions[0].symbol);
              lastNotificationTime = currentTime;
            })
            .catch(error => {
              console.error('Notification error:', error);
            });
        }
      }
    }

    setPreviousPositions(positionsArray);
    setPositions(positionsArray);
    calculateStats(positionsArray);
  });

  // Cleanup
  return () => {
    unsubscribe();
    notifiedPositions.clear();
  };
}, [user, isSoundEnabled] ); 




const calculateStats = (positionsData) => {
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  
  const closedPositions = positionsData.filter(pos => pos.status === 'closed');
  const openPositions = positionsData.filter(pos => pos.status === 'open');

  const realizedPnl = closedPositions.reduce((sum, pos) => 
    sum + parseFloat(pos.profitOrLoss || 0), 0
  );

  const unrealizedPnl = openPositions.reduce((sum, pos) => 
    sum + parseFloat(pos.profitOrLoss || 0), 0
  );

  const todayClosedPositions = closedPositions.filter(position => {
    const closeTime = position.closedTimestamp 
      ? new Date(position.closedTimestamp) 
      : new Date(position.timestamp);
    return closeTime >= startOfToday;
  });

  const dailyProfit = todayClosedPositions.reduce((sum, pos) => 
    sum + parseFloat(pos.profitOrLoss || 0), 0
  );
  const dailyTrades = todayClosedPositions.length;

  const timestamps = closedPositions.map(pos => 
    pos.closedTimestamp ? new Date(pos.closedTimestamp) : new Date(pos.timestamp)
  ).sort((a, b) => a - b);

  let hourlyStatsData = {
    totalHours: 0,
    totalProfit: realizedPnl,
    totalTrades: closedPositions.length,
    avgProfitPerTrade: 0,
    avgTradesPerHour: 0
  };

  if (timestamps.length >= 2) {
    const firstTrade = timestamps[0];
    const lastTrade = timestamps[timestamps.length - 1];
    const hoursDiff = Math.max(1, Math.ceil((lastTrade - firstTrade) / (1000 * 60 * 60)));
    
    hourlyStatsData = {
      totalHours: hoursDiff,
      totalProfit: realizedPnl,
      totalTrades: closedPositions.length,
      avgProfitPerTrade: realizedPnl / closedPositions.length,
      avgTradesPerHour: closedPositions.length / hoursDiff
    };
  }

  setHourlyStats(hourlyStatsData);
  setStats({
    totalProfitLoss: `${realizedPnl.toFixed(2)}$ - ${closedPositions.length} trades`,
    unrealizedPnl: `${unrealizedPnl.toFixed(2)}$ - ${openPositions.length} trades`,
    dailyProfit: `${dailyProfit.toFixed(2)}$ - ${dailyTrades} trades`,
    hourlyProfit: hourlyStatsData.totalHours > 0 
      ? `${(hourlyStatsData.totalProfit / hourlyStatsData.totalHours).toFixed(2)}$ - ${(hourlyStatsData.totalTrades / hourlyStatsData.totalHours).toFixed(2)} trades`
      : "0.00$ - 0 trades"
  });
};

     

  const getFilteredPositions = () => {
    const filteredByTab = positions.filter(position => position.status === activeTab);
    if (!showAllPositions) {
      return filteredByTab.slice(0, INITIAL_POSITION_COUNT);
    }
    return filteredByTab;
  };

  const renderShowMoreButton = () => {
    const filteredPositions = positions.filter(position => position.status === activeTab);
    if (filteredPositions.length > INITIAL_POSITION_COUNT && !showAllPositions) {
      return (
        <button 
          onClick={() => setShowAllPositions(true)}
          className="show-more-button"
        >
          Tümünü Göster ({filteredPositions.length} pozisyon)
        </button>
      );
    }
    return null;
  };

  const getCoinLogo = (symbol) => {
    let baseSymbol = symbol.replace(/^\d+/, '');
    baseSymbol = baseSymbol.replace(/\s*\([^)]*\)\s*/, '');
    baseSymbol = baseSymbol.replace(/USDT$|BUSD$|USD$/, '');
    baseSymbol = baseSymbol.trim();
    return coinLogos[baseSymbol] || '/images/default-coin.png';
  };

  const handleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user.email === "zaferkekik@gmail.com" || result.user.email === "gulten@zake.com.tr") {
        setUser(result.user);
      } else {
        alert("Yetkisiz giriş.");
        await signOut(auth);
      }
    } catch (error) {
      console.error("Giriş hatası:", error);
      alert("Giriş başarısız oldu.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  };

  if (!user) {
    return (
        <>
            <div className="menu-bar">
                <div className="logo">
                    <img src="/images/logo.png" alt="Logo" />
                    <h1>ZQ-Signals</h1>
                </div>
            </div>
            <div className="login-container">
                <div className="login-content">
                    <img src="/images/logo.png" alt="Logo" className="login-logo" />
                    <h1>ZQ-Signals</h1>
                    <button 
                        onClick={handleLogin}
                        className="login-button"
                    >
                        <i className="fab fa-google"></i>
                        Google ile Giriş
                    </button>
                </div>
            </div>
        </>
    );
  }

  return (
    <div>
        <div className="menu-bar">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
            <h1>ZQ-Signals</h1>
          </div>
          <div className="menu-controls">
            <button 
              onClick={toggleSound}
              className="sound-toggle"
              title={isSoundEnabled ? 'Sesi Kapat' : 'Sesi Aç'}
            >
              <i className={`fas ${isSoundEnabled ? 'fa-volume-up' : 'fa-volume-mute'}`}></i>
            </button>
            <button className="exit-button" onClick={handleLogout}>
              Exit
            </button>
          </div>
        </div>

        <div className="tab-menu">
          <button 
            onClick={() => setActiveTab('open')}
            className={`tab-link ${activeTab === 'open' ? 'active' : ''}`}
          >
            Aktif ({positions.filter(p => p.status === 'open').length})
          </button>
          <button 
            onClick={() => setActiveTab('closed')}
            className={`tab-link ${activeTab === 'closed' ? 'active' : ''}`}
          >
            Kapali
          </button>
        </div>

        <div className="container mx-auto" style={{ maxWidth: '1000px', padding: '0 15px' }}>
          <div className="welcome-text">
            Hoş geldiniz, {user.displayName}
          </div>

 
 
          <Stats 
          stats={stats} 
          hourlyStats={hourlyStats} 
          walletInfo={walletInfo}
          onWalletStatsClick={() => setShowWalletStats(true)}  // Stats'a click handler'ı ekle
        />

              {/* Risk Status komponentini Stats'dan sonra ekle */}
      <RiskStatus walletInfo={walletInfo} />

        <WalletStatsModal 
          isOpen={showWalletStats}
          onClose={() => setShowWalletStats(false)}
          walletInfo={walletInfo}
        />





          <div className="position-cards mt-4">
          {getFilteredPositions().map((position, index) => (
            <div 
              key={position.key || position.timestamp + index}
              className="position-card"
              onClick={() => handlePositionClick(position, position.key)}
            >
                <img 
                  src={getCoinLogo(position.symbol)}
                  alt={position.symbol}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/images/default-coin.png';
                  }}
                />
                <div className="card-content">
                  <div>
                    <div className="card-symbol">{position.symbol}</div>
                    <div className="card-strategy">
                      {position.side && (
                        <span className="position-type" style={{
                          padding: '1px 8px',
                          borderRadius: '3px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          backgroundColor: position.side === 'long' ? 'rgba(34, 197, 94, 0.2)' : 
                                        position.side === 'short' ? 'rgba(239, 68, 68, 0.2)' :
                                        position.side === 'hedge' ? 'rgba(59, 130, 246, 0.2)' :
                                        'rgba(156, 163, 175, 0.2)',
                          color: position.side === 'LONG' ? '#22c55e' : 
                                position.side === 'SHORT' ? '#ef4444' : 
                                position.side === 'HEDGE' ? '#3b82f6' : 
                                '#6b7280'
                        }}>
                          {position.side === 'long' ? 'L' : 
                          position.side === 'short' ? 'S' : 
                          position.side === 'hedge' ? 'H' : 
                          '?'}
                        </span>
                      )} • {position.strategy || 'ZQTX003'}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className={parseFloat(position.profitOrLoss) >= 0 ? 'profit-positive' : 'profit-negative'}>
                      ${parseFloat(position.profitOrLoss).toFixed(2)} • {parseFloat(position.profitOrLossPercentage).toFixed(2)}
                    </div>
                    <div className="text-sm text-gray-500">
                      {new Date(position.timestamp).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {renderShowMoreButton()}
          </div>

          {selectedPosition && (
            <div className="modal" onClick={() => setSelectedPosition(null)}>
              <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="header-content">
                  <img 
                    src={getCoinLogo(selectedPosition.symbol)}
                    alt={selectedPosition.symbol}
                    className="coin-logo"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/default-coin.png';
                    }}
                  />
                  <div className="symbol-info">
                    <div className="symbol-text">{selectedPosition.symbol}</div>
                    <div className="strategy-text">
                      {selectedPosition.strategy || 'ZQTX004'}
                    </div>
                  </div>

                  <button 
                    onClick={(e) => {
                      e.stopPropagation(); // Event'in ana modal'a gitmesini engelle
                      setPnlUpdateModalOpen(true);
                 
                    }}
                    className="update-pnl-button"
                    style={{
                      backgroundColor: '#4f46e5',
                      color: 'white',
                      padding: '5px 12px',
                      borderRadius: '4px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginRight: '8px'
                    }}
                  >
                    PNL
                  </button>

                  <div style={{ display: 'flex', gap: '8px', marginRight: '10px' }}>
                    <button 
                      onClick={() => {
                        const cleanSymbol = selectedPosition.symbol
                          .replace(/\s*\([^)]*\)\s*/, '')
                          .replace(/(\w+)(USDT|USD|BUSD)$/, '$1$2.P');
                        
                        const tradingViewUrl = `https://tr.tradingview.com/chart?symbol=BYBIT%3A${encodeURIComponent(cleanSymbol)}`;
                        window.open(tradingViewUrl, '_blank', 'noopener,noreferrer');
                      }}
                      className="trading-view-button"
                      style={{
                        backgroundColor: '#2962ff',
                        color: 'white',
                        padding: '5px 12px',
                        borderRadius: '4px',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      TV
                    </button>

                    <button 
                      onClick={() => {
                        const cleanSymbol = selectedPosition.symbol
                          .replace(/\s*\([^)]*\)\s*/, '')
                          .replace(/(\w+)(USDT|USD|BUSD)$/, '$1$2');
                        
                        const bybitUrl = `https://www.bybit.com/trade/usdt/${cleanSymbol}`;
                        window.open(bybitUrl, '_blank', 'noopener,noreferrer');
                      }}
                      className="bybit-button"
                      style={{
                        backgroundColor: '#f7a600',
                        color: 'white',
                        padding: '5px 12px',
                        borderRadius: '4px',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      Bybit
                    </button>
                  </div>

                  <button 
                    type="button" 
                    className="close" 
                    onClick={() => setSelectedPosition(null)} 
                    data-dismiss="modal" 
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="table-container">
                  <table className="message-table">
                    <thead>
                      <tr>
                        <th style={{width: '33%'}}>Zaman</th>
                        <th>Mesaj</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(selectedPosition.messages || {}).map(([key, msg], index) => (
                        <tr key={key}>
                          <td className="whitespace-nowrap">
                            {new Date(msg.timestamp).toLocaleString()}
                          </td>
                          <td>{msg.message?.replace('message: ', '')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="modal-footer">
                  <div className="footer-info">
                    <span>Son güncelleme: {new Date(selectedPosition.timestamp).toLocaleString()}</span>
                    <span className={`pnl-container ${parseFloat(selectedPosition.profitOrLoss) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                      PNL: {selectedPosition.profitOrLoss}
                    </span>
                  </div>
                  <button
                    className="delete-button"
                    onClick={() => handleDeletePosition(selectedPositionKey)}
                  >
                    Sil
                  </button>
                </div>


              </div>
            </div>
          )}

          {pnlUpdateModalOpen && selectedPosition && (
            <div 
              className="modal-overlay"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 2000
              }}
              onClick={(e) => {
                e.stopPropagation();
                setPnlUpdateModalOpen(false);
              }}
            >
              <div 
                className="pnl-update-modal" 
                onClick={(e) => e.stopPropagation()}
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                  width: '100%',
                  maxWidth: '400px'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                  <h3>PNL Güncelle: {selectedPosition.symbol}</h3>
                  <button 
                    onClick={() => setPnlUpdateModalOpen(false)}
                    style={{
                      background: 'none',
                      border: 'none',
                      fontSize: '20px',
                      cursor: 'pointer'
                    }}
                  >
                    ×
                  </button>
                </div>
                
                <div style={{ marginTop: '15px' }}>
                  <label>Mevcut PNL: ${selectedPosition.profitOrLoss}</label>
                  <input
                    type="number"
                    step="0.01"
                    value={newPnlValue}
                    onChange={(e) => setNewPnlValue(e.target.value)}
                    placeholder="Yeni PNL değeri"
                    style={{
                      width: '100%',
                      padding: '8px',
                      marginTop: '5px',
                      border: '1px solid #ddd',
                      borderRadius: '4px'
                    }}
                  />
                </div>

                <div style={{ 
                  display: 'flex', 
                  gap: '10px', 
                  marginTop: '20px',
                  justifyContent: 'flex-end' 
                }}>
                  <button
                    onClick={() => setPnlUpdateModalOpen(false)}
                    style={{
                      padding: '8px 16px',
                      borderRadius: '4px',
                      border: '1px solid #ddd',
                      backgroundColor: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    İptal
                  </button>
                  <button
                    onClick={() => handlePnlUpdate()}
                    style={{
                      padding: '8px 16px',
                      borderRadius: '4px',
                      border: 'none',
                      backgroundColor: '#4f46e5',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    Güncelle
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default TradingDashboard;