import React, { useState, useEffect } from 'react';

const RiskIndicator = ({ currentRate, warningRate, dangerRate, label, suggestionRange }) => {
  const [isBlinking, setIsBlinking] = useState(false);

  const getRiskLevel = (rate) => {
    if (rate >= dangerRate) return 'danger';
    if (rate >= warningRate) return 'warning';
    return 'safe';
  };

  const riskLevel = getRiskLevel(currentRate);

  useEffect(() => {
    if (riskLevel === 'danger') {
      const interval = setInterval(() => {
        setIsBlinking(prev => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [riskLevel]);

  const getBackgroundColor = () => {
    if (riskLevel === 'danger') {
      return isBlinking ? 'bg-red-100' : 'bg-red-50';
    }
    if (riskLevel === 'warning') return 'bg-yellow-50';
    return 'bg-green-50';
  };

  const getTextColor = () => {
    if (riskLevel === 'danger') return 'text-red-600';
    if (riskLevel === 'warning') return 'text-yellow-600';
    return 'text-green-600';
  };

  return (
    <div className={`px-3 py-2 rounded-lg ${getBackgroundColor()} transition-colors duration-300 text-center`}>
      <div className="text-gray-500 text-sm mb-0.5">{label}</div>
      <div className={`text-lg font-bold ${getTextColor()}`}>
        %{currentRate.toFixed(2)}
      </div>
      {suggestionRange && (
        <div className="text-xs text-gray-500 mt-0.5">
          Önerilen: {suggestionRange}
        </div>
      )}
    </div>
  );
};

const RiskStatus = ({ walletInfo }) => {
  const imRate = parseFloat(walletInfo?.accountIMRate || 0) * 100;
  const mmRate = parseFloat(walletInfo?.accountMMRate || 0) * 100;

  return (
    <div className="mt-3 flex justify-center gap-4">
      <RiskIndicator 
        currentRate={imRate}
        warningRate={20}
        dangerRate={25}
        label="IM Rate"
        suggestionRange="<%20"
      />
      <RiskIndicator 
        currentRate={mmRate}
        warningRate={8}
        dangerRate={10}
        label="MM Rate"
        suggestionRange="<%8"
      />
    </div>
  );
};

export default RiskStatus;